import "./Footer.css";

export default function Footer(props) {
  return (
    <h2 className="footer">
      <div style={{ float: "right", margin: "20px", fontSize: "0.5em" }}>
        <a href="/legal/terms">Terms</a> &nbsp;{" "}
        <a href="/legal/privacy">Privacy</a>
      </div>
      <small>
        <small style={{ fontWeight: "normal" }}>
          Politics. Sorted. &nbsp;© 2023{" "}
          <a href="https://www.decisionsort.com" target="_blank">
            DecisionSort, Inc.
          </a>
        </small>
      </small>
    </h2>
  );
}

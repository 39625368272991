import "./App.css";
import SimpleMode from "./SimpleMode";
import Header from "./Header";
import Footer from "./Footer";
import { Analytics } from "@vercel/analytics/react";

import { Switch, Route, Router } from "./util/router";
import LegalPage from "./legal";

function App() {
  return <Router> 
      <Analytics />
      <div className="app">
        <Header />
        <Switch>
          <Route exact path="/legal/:section" component={LegalPage} />
          <Route component={SimpleMode} />
          </Switch>
        <Footer />
      </div>
      </Router>
}

export default App;

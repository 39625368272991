export const chrisNotes = [
    {
      title: "Twitter",
      clips: [
        {
          content: [""],
          sourceName: "Twitter",
          link: "https://twitter.com/GovChristie",
        },
      ],
    },
    {
      title: "Website",
      clips: [
        {
          content: [""],
          sourceName: "Website",
          link: "https://chrischristie.com/",
        },
      ],
    },
    {
      title: "Domestic Policy",
      clips: [
        {
          content: [
            "Christie would not support a federal ban on abortion, instead arguing that the issue should be addressed at the state level.",
            "Christie has argued for some overhauls [of entitlements] before they go bankrupt.  During his presidential run in 2015, Christie proposed a series of policies to shore up Social Security, including an income cap, raising the retirement age and means testing.",
            "Christy has tied border security to one of his signature issues, the opioid epidemic, calling to secure the border to stop the flow of fentanyl into the country.",
            "Christie has recently attributed mass shootings to issues with mental health. He has also shifted positions on an assault weapons ban, having supported a ban in the '90s but opposed it when he ran for president in 2016.",
            "Christie has supported U.S. aid to Ukraine, and he argued that helping Ukraine in its war against Russia also sends a message to other adversaries that the U.S. will support its allies.",
          ],
          sourceName: "NBC",
          source:
            "https://www.nbcnews.com/meet-the-press/meetthepressblog/chris-christie-issues-rcna88149",
        },
      ],
    },
    {
      title: "Experience",
      clips: [
        {
          content: [
            "Christie was originally a big-tent Republican when he became governor of New Jersey, and enjoyed extremely high approval ratings.",
            'After "Bridgegate", a failed run for President, and endorsing Trump and then being fired by Trump, he left office as one of the least popular governors.',
          ],
          sourceName: "Business Insider",
          source:
            "https://www.businessinsider.com/how-chris-christie-became-least-popular-governor-in-new-jersey-history-2018-1?op=1",
        },
        {
          content: [
            "Wrote the books 'Let Me Finish' (2019) and 'Republican Rescue' (2021).",
          ],
          sourceName: "Amazon",
          source:
            "https://www.amazon.com/Chris-Christie-Books/s?k=Chris+Christie&rh=n%3A283155",
        },
      ],
    },

    {
      title: "Person / Character",
      clips: [
        {
          content: [
            "Christie 'was Trump before Trump'.",
            "Christie was fired from Trump's transition team after warning the team about Michael Flynn.",
          ],
          sourceName: "Politico",
          source:
            "https://www.politico.com/news/magazine/2023/06/06/chris-christie-55-things-00100058",
        },
        {
          content: [
            "Christie questions why non-Republican voters would vote for Trump.",
          ],
          sourceName: "Video: CNBC",
          source:
            "https://www.cnbc.com/video/2023/08/01/fmr-nj-gov-chris-christie-trump-will-probably-be-out-on-bail-by-the-time-we-get-to-debates.html",
        },
        {
          content: [
            "Christie supported Trump and his policies throughout his presidency, endorsed his re-election campaign, was his re-election campaign debate preparer, and said in his book in 2021 that he'd vote for him in 2024; and now he has decided that Trump didn’t fulfill his campaign promises, lied about the election results and incited the Capitol riots.",
          ],
          sourceName: "The Trentonian",
          source:
            "https://www.trentonian.com/2023/07/30/a-progressive-perspective-why-is-chris-christie-running-again-irwin-stoolmacher-column/",
        },
      ],
    },
  ]
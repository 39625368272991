export const vivekNotes = [
    {
      title: "Twitter",
      clips: [
        {
          content: [""],
          sourceName: "Twitter",
          link: "https://twitter.com/VivekGRamaswamy",
        },
      ],
    },
    {
      title: "Website",
      clips: [
        {
          content: [""],
          sourceName: "Website",
          link: "https://www.vivek2024.com/",
        },
      ],
    },
    {
      title: "Domestic Policy",
      clips: [
        {
          content: [
            'Vivek has 25 policy commitments, starting with "Revive American National Identity" and including "Declare Indpedence from China" and "Dismantle Managerial Bureaucracy".',
            'He wants to use the military to secure our southern border, ban addictive social media under age 16, ban gender "confusion" care for minors, and withhold funding for cities that refuse to protect Americans from violent crime.',
            'He envisions shutting down the Department of Education, FBI, IRS, and more, "rebuilding when necessary", 8-year term limits for bureaucrats, eliminating federal employee unions, and moving 75% of federal employees out of D.C.',
          ],
          sourceName: "Campaign Website",
          source: "https://www.vivek2024.com/america-first-2-0/",
        },
      ],
    },
    {
        title: "Foreign Policy",
        clips: [
          {
            content: [
              'Vivek wants to "Declare Indpedence from China" through economic policies at home. He will "hold the CCP accountable for Covid-19: use all financial levers."',
              'He wants to use the military to secure our southern border and annihilate Mexican drug cartels.',
              'He would stop CCP affiliates from buying American land and ban businesses from expanding in China until the CCP stops cheating.',
            ],
            sourceName: "Campaign Website",
            source: "https://www.vivek2024.com/issue/declare-independence-from-communist-china/",
          },
        ],
      },
    {
      title: "Economy",
      clips: [
        {
          content: [
            '"Drill, frack, and burn coal...unshackle nuclear energy", dismantle LBJ\'s Great Society, incentive trade schools, cut 75% headcount among US regulators.',
            '"Limit the Fed\'s scope: stabilize the dollar & nothing more."',
          ],
          sourceName: "Campaign Website",
          source: "https://www.vivek2024.com/america-first-2-0/",
        },
      ],
    },

    {
      title: "Person / Character",
      clips: [
        {
          content: [
            "Vivek is openly Hindu, but attended Catholic school and speaks in familiar Judeo-Christian language when discussing America's socio-political roots.",
          ],
          sourceName: "Washington Post",
          source:
            "https://www.msn.com/en-us/news/other/vivek-ramaswamy-and-the-christian-language-of-hinduism/ar-AA1eBs14",
        },
      ],
    },
    {
      title: "Experience",
      clips: [
        {
          content: [
            "Wrote the books 'Woke, Inc' (2023), 'Capitalist Punishment' (2023) and 'Nation of Victims' (2023).",
          ],
          sourceName: "Amazon",
          source:
            "https://www.amazon.com/stores/Vivek-Ramaswamy/author/B096HGH64Z",
        },
      ],
    },
  ]
export const nikkiNotes = [
  {
    title: "Twitter",
    clips: [
      {
        content: [""],
        sourceName: "Twitter",
        link: "https://twitter.com/NikkiHaley",
      },
    ],
  },
  {
    title: "Website",
    clips: [
      {
        content: [""],
        sourceName: "Website",
        link: "https://nikkihaley.com/",
      },
    ],
  },
  {
    title: "Domestic Policy",
    clips: [
      {
        content: [
          "As governor, Haley opposed efforts to expand Medicaid under the ACA (one of 11 states that hasn't expanded Medicaid).",
          'She said in a 2016 reponse to Obama\'s State of the Union, "No one who is willing to work hard, abide by our laws, and love our traditions should ever feel unwelcome in this country.' +
            "..At the same time, that does not mean we just flat out open our borders." +
            '..We must fix our broken immigration system. That means stopping illegal immigration. And it means welcoming properly vetted legal immigrants..."',
          'Haley generally opposes abortion rights, and has been an "ardent tax-cutter" as governor.',
        ],
        sourceName: "NBC",
        source:
          "https://www.nbcnews.com/meet-the-press/meetthepressblog/nikki-haley-stands-key-issues-rcna70838",
      },
      {
        content: [
          "\"On Trump's policy, I agree with everything that he's done.\"",
        ],
        sourceName: "Politico",
        source:
          "https://www.politico.com/interactives/2021/magazine-nikki-haleys-choice/",
      },
    ],
  },
  {
    title: "Foreign Policy",
    clips: [
      {
        content: [
          "\"On Trump's policy, I agree with everything that he's done.\"",
        ],
        sourceName: "Politico",
        source:
          "https://www.politico.com/interactives/2021/magazine-nikki-haleys-choice/",
      },
      {
        content: [
          'When she resigned after 2 years as ambassador to the UN, she said, "Now, the United States is respected," Haley said at the White House, seated next to President Donald Trump. "Countries may not like what we do, but they respect what we do."',
        ],
        sourceName: "NBC",
        source: "",
      },
    ],
  },
  {
    title: "Experience",
    clips: [
      {
        content: [
          'The New York Times claimed she was "that rarest of Trump appointees: one who can exit the administration with her dignity largely intact."',
        ],
        sourceName: "NYT",
        source:
          "https://www.nytimes.com/2018/10/09/opinion/nikki-haley-will-be-missed.html",
      },
      {
        content: [
          "In part for her insistence on the Confederate flag being removed from the South Carolina capitol, TIME magazine listed her as of the 100 most influential people in the world.",
          "As governor of South Carolina, she prioritized luring industry to her state. She left office with ~400,000 more people employed than when she had taken office six years before.",
          "She served as US ambassador to the UN from January 2017 to December 2018. She resigned for unknown reasons.",
        ],
        sourceName: "Politico",
        source:
          "https://www.politico.com/news/magazine/2023/02/14/nikki-haley-2024-bio-what-you-need-to-know-00082742",
      },
    ],
  },
];

import CountdownTimer from "./CountdownTimer";
import "./Header.css";

export default function Header(props) {
  return (
    <>
      <CountdownTimer
        countdownDate={new Date("December 6, 2023 20:00:00").getTime()}
        countdownName="fourth Republican debate"
      />
      <h2>
        <a href="/" style={{textDecoration:"none",color:"black"}}><img
          src="/images/polly_color.png"
          alt="logo"
          style={{ verticalAlign: "middle", marginRight: "15px" }}
        />
        PollySort</a>
        <div
          style={{
            fontSize: "12pt",
            marginLeft: "5px",
            marginTop: "10px",
            fontWeight: "normal",
          }}
        >
          A <a href="https://www.decisionsort.com" target="_blank">DecisionSort</a> company
        </div>
      </h2>
    </>
  );
}

import "./SimpleMode.css";
import { AnimatePresence, Reorder, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CandidatesRanked from "./CandidatesRanked";
import FinalResults from "./FinalResults";
import InitialQuestions from "./InitialQuestions";
import { List, candidates, notes } from "./data.js";
import axios from "axios";
import Takeaways from "./Takeaways";
import Contact from "./Contact.js";

export default function SimpleMode(props) {
  const dataVersionNumber = 0.9;
  const [aspectsReviewed, setAspectsReviewed] = useState(
    JSON.parse(localStorage.getItem("aspectsReviewed")) || ["Domestic Policy"]
  );
  const [items, setItems] = useState(
    (JSON.parse(localStorage.getItem("candidateRankings")) &&
      JSON.parse(localStorage.getItem("candidateRankings"))[0]
        .candidateRankings) ||
      List
  );
  const [aspectShown, setAspectShown] = useState(
    localStorage.getItem("aspectShown") || "Domestic Policy"
  );
  const [usableWindowWidth, setUsableWindowWidth] = useState(
    Math.max(document.documentElement.clientWidth || 0)
  );

  window.addEventListener("resize", (e) =>
    setUsableWindowWidth(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    )
  );
  const rankingRevisionNumber = useRef(
    localStorage.getItem("rankingRevisionNumber")
      ? parseInt(localStorage.getItem("rankingRevisionNumber"))
      : 0
  );
  const currentSaveRankingsTimer = useRef(null);
  const [showingInitQuestions, setShowingInitQuestions] = useState(
    !localStorage.getItem("accountId")
  );
  const showingInitQuestionsRef = useRef(showingInitQuestions);
  const [showResults, setShowResults] = useState(
    localStorage.getItem("showResults") === "yes" || false
  );
  const [finalResults, setFinalResults] = useState({
    favorable: [],
    unfavorable: [],
  });

  useEffect(() => {
    localStorage.setItem("aspectsReviewed", JSON.stringify(aspectsReviewed));
    saveToMongo();
  }, [aspectsReviewed]);
  useEffect(() => {
    localStorage.setItem("showResults", showResults ? "yes" : "no");
    saveToMongo();
  }, [showResults]);
  useEffect(() => {
    localStorage.setItem("aspectShown", aspectShown);
    saveToMongo();
  }, [aspectShown]);

  const createAccount = (initialList) => {
    localStorage.setItem("accountId", crypto.randomUUID());
    localStorage.setItem("aspectsReviewed", JSON.stringify(aspectsReviewed));
    localStorage.setItem("aspectShown", aspectShown);
    localStorage.setItem("showResults", showResults? "yes":"no");
    localStorage.setItem(
      "rankingRevisionNumber",
      rankingRevisionNumber.current
    );
    queueSaveRankings(initialList, true);
  };

  const saveRankings = useCallback((updatedList) => {
    currentSaveRankingsTimer.current = null;
    localStorage.setItem("dataVersionNumber", dataVersionNumber);
    if (!localStorage.getItem("candidateRankings")) {
      localStorage.setItem(
        "candidateRankings",
        JSON.stringify([
          {
            rankingRevisionNumber: rankingRevisionNumber.current,
            candidateRankings: updatedList,
            modified: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    } else {
      let currentCandidatesRanked = JSON.parse(
        localStorage.getItem("candidateRankings")
      );
      if (
        currentCandidatesRanked[0].rankingRevisionNumber !==
        rankingRevisionNumber.current
      ) {
        localStorage.setItem(
          "candidateRankings",
          JSON.stringify([
            {
              rankingRevisionNumber: rankingRevisionNumber.current,
              candidateRankings: updatedList,
              modified: new Date(Date.now()).toUTCString(),
            },
            ...currentCandidatesRanked,
          ])
        );
      } else {
        currentCandidatesRanked.splice(0, 1, {
          rankingRevisionNumber: rankingRevisionNumber.current,
          candidateRankings: updatedList,
          modified: new Date(Date.now()).toUTCString(),
        });
        localStorage.setItem(
          "candidateRankings",
          JSON.stringify(currentCandidatesRanked)
        );
      }
    }
    saveToMongo();
  }, []);

  const queueSaveRankings = useCallback(
    (updatedList) => {
      if (!currentSaveRankingsTimer.current)
        currentSaveRankingsTimer.current = setTimeout(
          () => saveRankings(updatedList),
          1000
        );
      else {
        clearTimeout(currentSaveRankingsTimer.current);
        currentSaveRankingsTimer.current = setTimeout(
          () => saveRankings(updatedList),
          1000
        );
      }
    },
    [saveRankings]
  );

  const rankOrderCentroid = (startingIndex, arrayLength) => {
    let partialSum = 0;
    for (let k = startingIndex; k < arrayLength; k++) {
      partialSum += 1 / (k + 1);
    }
    partialSum *= 1 / arrayLength;
    return partialSum;
  };

  const candidateCoords = useMemo(() => {
    let candidateX = {};
    let candidateY = {};

    candidates.forEach((candidate, i) => {
      console.log(finalResults["favorable"][i]);
      candidateY[candidate.name] = Math.round(
        (10 * finalResults["favorable"][i]) / 0.4
      );
      candidateX[candidate.name] = Math.round(
        10 * finalResults["unfavorable"][i]
      );

      // if (candidateX[candidate.name] + candidateY[candidate.name] > 12) {
      //   candidateX[candidate.name] =
      //     candidateX[candidate.name] -
      //     Math.round(
      //       (candidateX[candidate.name] + candidateY[candidate.name] - 12) * 0.5
      //     );
      //   candidateY[candidate.name] =
      //     candidateY[candidate.name] -
      //     Math.round(
      //       candidateX[candidate.name] + candidateY[candidate.name] - 12
      //     );
      // }
      //  - candidateY[candidate.name] + candidateX[candidate.name];

      // Math.floor(
      //     (10 * finalResults["unfavorable"][i]) /
      //       Math.max.apply(Math, finalResults["unfavorable"])
      //   ) +
      //   Math.floor(
      //     (5 * finalResults["favorable"][i]) /
      //       Math.max.apply(Math, finalResults["favorable"])
      //   );
      // Math.floor(10 * finalResults["unfavorable"][i]/props.candidates.length);
    });
    return [candidateX, candidateY];
  }, [finalResults]);
  const duplicate = (candidateName) => {
    // if (candidateName === "Vivek Ramaswamy") console.log(candidateCoords);
    for (let i = 0; i < 11; i++) {
      if (Object.keys(candidateCoords[0])[i] === candidateName) continue;
      if (
        candidateCoords[0][Object.keys(candidateCoords[0])[i]] ===
          candidateCoords[0][candidateName] &&
        candidateCoords[1][Object.keys(candidateCoords[1])[i]] ===
          candidateCoords[1][candidateName]
      )
        return true;
    }
    return false;
  };

  const updateRankOrder = useCallback(() => {
    let newFinalResults = { favorable: [], unfavorable: [] };
    let newItemsWeights = {};
    let newItemCandidateWeights = {};
    queueSaveRankings(items);
    items.forEach((item, i) => {
      let weightOfItem = rankOrderCentroid(i, items.length);
      newItemsWeights[item.name] = weightOfItem;
      newItemCandidateWeights[item.name] = [];
      candidates.forEach((candidate, j) => {
        let weightOfCandidate =
          item.candidatesOrder.indexOf(j) > item.candidatesOrder.indexOf("a") &&
          item.candidatesOrder.indexOf(j) < item.candidatesOrder.indexOf("d")
            ? 0
            : rankOrderCentroid(
                item.candidatesOrder
                  .filter((co) => co !== "a" && co !== "d")
                  .indexOf(j),
                candidates.length
              );

        if (item.candidatesOrder.indexOf(j) > item.candidatesOrder.indexOf("d"))
          weightOfCandidate = weightOfCandidate - 1;

        newItemCandidateWeights[item.name].push(weightOfCandidate);
      });
    });
    candidates.forEach((c, i) => {
      newFinalResults["favorable"].push(0);
      newFinalResults["unfavorable"].push(0);
      Object.keys(newItemCandidateWeights).forEach((cwk) => {
        // console.log(newItemCandidateWeights[cwk][i],newItemsWeights[cwk]);
        if (newItemCandidateWeights[cwk][i] > 0)
          newFinalResults["favorable"][i] +=
            newItemCandidateWeights[cwk][i] * newItemsWeights[cwk];
        else if (newItemCandidateWeights[cwk][i] < 0)
          newFinalResults["unfavorable"][i] +=
            Math.abs(newItemCandidateWeights[cwk][i]) * newItemsWeights[cwk];
        // if (c.name === "Donald Trump" || c.name === "Ron DeSantis")
        //   console.log(
        //     c.name,
        //     newFinalResults["favorable"][i],
        //     " and ",
        //     newFinalResults["unfavorable"][i]
        //   );
      });
    });
    setFinalResults(newFinalResults);
  }, [items, queueSaveRankings]);

  useEffect(() => {
    updateRankOrder();
  }, [updateRankOrder]);

  const changeOrder = (newOrder) => {
    if (newOrder.indexOf("d") > newOrder.indexOf("a")) {
      let newItems = [...items];
      newItems.find((l) => l.name === aspectShown).candidatesOrder = newOrder;
      setItems(newItems);
      updateRankOrder();
    } else {
      setItems([...items]);
    }
  };

  const endInitQuestions = (candidatesFavor) => {
    if (!showingInitQuestionsRef.current) return;
    showingInitQuestionsRef.current = false;
    setShowingInitQuestions(false);
    let newItems = [...items];
    newItems.forEach((ni, i) => {
      newItems[i].candidatesOrder = [].concat(
        candidatesFavor
          .filter((cf) => cf.favored > 0)
          .sort((a, b) => 0.5 - Math.random())
          .map((cf) => cf.id),
        "a",
        candidatesFavor
          .filter((cf) => cf.favored === 0)
          .sort((a, b) => 0.5 - Math.random())
          .map((cf) => cf.id),
        "d",
        candidatesFavor
          .filter((cf) => cf.favored < 0)
          .sort((a, b) => 0.5 - Math.random())
          .map((cf) => cf.id)
      );
      createAccount(newItems);
      setItems(newItems);
    });
  };

  const linkClicked = (link) => {
    let currentLinkClicks = localStorage.getItem("linksClicked");
    if (!currentLinkClicks)
      localStorage.setItem(
        "linksClicked",
        JSON.stringify([
          {
            link: link,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    else
      localStorage.setItem(
        "linksClicked",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("linksClicked")),
          {
            link: link,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    saveToMongo();
  };

  const saveToMongo = () => {
    if (!localStorage.getItem("accountId")) return;
    let formattedCandidateRankings = [];
    JSON.parse(localStorage.getItem("candidateRankings")).forEach((fcr, i) => {
      formattedCandidateRankings[i] = {};
      formattedCandidateRankings[i].rankingRevisionNumber =
        fcr.rankingRevisionNumber;
      formattedCandidateRankings[i].candidateRankings =
        fcr.candidateRankings.map((cr) => {
          return {
            aspectName: cr.name,
            candidatesOrder: cr.candidatesOrder.map((co) => "" + co + ""),
          };
        });
      formattedCandidateRankings[i].modified = fcr.modified;
    });
    let listToSave = {
      accountId: localStorage.getItem("accountId"),
      dataVersionNumber: dataVersionNumber,
      candidateRankings: formattedCandidateRankings,
      rankingRevisionNumber: localStorage.getItem("rankingRevisionNumber"),
      showResults: localStorage.getItem("showResults"),
      aspectsReviewed: JSON.parse(localStorage.getItem("aspectsReviewed")),
      aspectShown: localStorage.getItem("aspectShown"),
      candidatesClicked: JSON.parse(localStorage.getItem("candidatesClicked")),
      notesClicked: JSON.parse(localStorage.getItem("notesClicked")),
      linksClicked: JSON.parse(localStorage.getItem("linksClicked")),
    };
    console.log(listToSave);
    axios
      .post("/api/savetodb", {
        rankedList: listToSave,
      })
      .then((res) => console.log(res));
  };

  return (
    <AnimatePresence>
      {showingInitQuestions ? (
        <InitialQuestions
          aspects={List}
          candidates={candidates}
          endInitQuestions={endInitQuestions}
        />
      ) : (
        <motion.div
          key="secondStep"
          className="mainWrapper"
          initial={{
            height: 0,
            opacity: 0,
          }}
          animate={{
            height: "auto",
            opacity: 1,
            transition: {
              height: {
                duration: 1,
                delay: 1,
              },
              opacity: {
                duration: 1,
                delay: 1,
              },
            },
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.2,
              },
              opacity: {
                duration: 1,
              },
            },
          }}
        >
          {" "}
          <h3>
            {showResults ? (
              <>
                <i className="fa-solid fa-star" style={{ float: "left" }} />
                <i className="fa-solid fa-star" style={{ float: "right" }} />
                3. Weighted Scores of Candidates
              </>
            ) : (
              <>
                <i className="fa-solid fa-star" style={{ float: "left" }} />
                <i className="fa-solid fa-star" style={{ float: "right" }} />
                2. Rank Candidates For Each Issue
              </>
            )}
            {showResults ? (
              <button
                className="resultsButton resultsButtonBack"
                onClick={() => setShowResults(!showResults)}
              >
                &nbsp;
                <i className="fa-solid fa-arrow-left" />
                &nbsp;(Back to Rankings)
              </button>
            ) : (
              <div className="headerNav">
                <i
                  style={{
                    visibility:
                      items.findIndex((item) => item.name === aspectShown) > 0
                        ? "visible"
                        : "hidden",
                  }}
                  className="fa-solid fa-arrow-left"
                  onClick={() => {
                    // update our list of aspects reviewed
                    if (
                      aspectsReviewed.indexOf(
                        items[
                          items.findIndex((item) => item.name === aspectShown) -
                            1
                        ].name
                      ) < 0
                    ) {
                      let newAspectsReviewed = [...aspectsReviewed];
                      newAspectsReviewed.push(
                        items[
                          items.findIndex((item) => item.name === aspectShown) -
                            1
                        ].name
                      );
                      setAspectsReviewed(newAspectsReviewed);
                    }
                    setAspectShown(
                      items[
                        items.findIndex((item) => item.name === aspectShown) - 1
                      ].name
                    );
                  }}
                />
                {aspectsReviewed.length >= items.length && (
                  <button
                    className="resultsButton"
                    onClick={() => {
                      if (!showResults) {
                        rankingRevisionNumber.current += 1;
                        localStorage.setItem(
                          "rankingRevisionNumber",
                          rankingRevisionNumber.current
                        );
                        saveRankings(items, true);
                      }
                      setShowResults(!showResults);
                    }}
                  >
                    See Results
                  </button>
                )}{" "}
                <i
                  className="fa-solid fa-arrow-right"
                  style={{
                    visibility:
                      items.findIndex((item) => item.name === aspectShown) <
                      items.length - 1
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={() => {
                    // update our list of aspects reviewed
                    if (
                      aspectsReviewed.indexOf(
                        items[
                          items.findIndex((item) => item.name === aspectShown) +
                            1
                        ].name
                      ) < 0
                    ) {
                      let newAspectsReviewed = [...aspectsReviewed];
                      newAspectsReviewed.push(
                        items[
                          items.findIndex((item) => item.name === aspectShown) +
                            1
                        ].name
                      );
                      setAspectsReviewed(newAspectsReviewed);
                    }
                    setAspectShown(
                      items[
                        items.findIndex((item) => item.name === aspectShown) + 1
                      ].name
                    );
                  }}
                />
              </div>
            )}
          </h3>
          {/* <span style={{ marginRight: "200px" }}>
            {!show2D && aspectShown && (
              <>
                {" "}
                <i
                  className="fa-solid fa-circle-info"
                  style={{
                    color: "gray",
                  }}
                />{" "}
                ={" "}
                {showResults ? (
                  <span> Weighted Score &nbsp;&nbsp;&nbsp;</span>
                ) : (
                  "more information"
                )}
              </>
            )}
          </span> */}
          <div className="issuesContainer">
            <h2
              style={{
                marginBottom: "0px",
                marginTop: "1px",
                marginLeft: "-20px",
                background: showResults
                  ? "rgb(196, 220, 239) linear-gradient(0deg,rgb(196, 220, 239) 0%, #e8f8ff 100%)"
                  : "",
                textAlign: "center",
                borderRadius: "10px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              {!showResults ? (
                <span className="aspectName">Issues</span>
              ) : (
                <>
                  Sort Issues By Importance
                  <div
                    style={{
                      fontSize: ".9em",
                      fontWeight: "normal",
                    }}
                  >
                    (Drag to sort)
                  </div>
                </>
              )}
            </h2>
            {/* {showResults && (
              <>
                <div
                  style={{
                    position: "absolute",
                    left: "-100px",
                    top: "270px",
                    transform: "rotate(270deg)",
                  }}
                >
                  Importance to You <i className="fa-solid fa-arrow-right" />
                </div>
              </>
            )} */}
            {showResults && (
              <ul
                className="aspectName"
                style={{
                  position: "absolute",
                  left: "-30px",
                  top: "63px",
                  listStyle: "none",
                  fontWeight: "bold",
                  fontSize: "1.5em",
                }}
              >
                {items.map((it, i) => (
                  <li style={{ marginBottom: "51px", opacity: 1 - i * 0.15 }}>
                    {i + 1}.
                  </li>
                ))}
              </ul>
            )}
            {usableWindowWidth > 875 ? (
              <Reorder.Group
                axis="y"
                values={items}
                onReorder={setItems}
                as={"div"}
                key="rankedListAspects"
                style={{ paddingInlineStart: "0px" }}
              >
                {items.map((item, i) => (
                  <Reorder.Item
                    key={item.name}
                    value={item}
                    as={"div"}
                    dragListener={showResults}
                    className={
                      (aspectShown === item.name && !showResults
                        ? "aspectNameShown"
                        : "aspect") +
                      (aspectsReviewed.indexOf(item.name) < 0
                        ? " unreviewed"
                        : "")
                    }
                    style={{
                      position: "relative",
                      cursor: !showResults ? "pointer" : "grab",
                      color: showResults
                        ? "rgb(" + i * 40 + "," + i * 40 + "," + i * 40 + ")"
                        : "rgb(0,0,0)",

                      fontWeight: showResults ? "bold" : "",
                    }}
                    onClick={() => {
                      // update our list of aspects reviewed
                      if (aspectsReviewed.indexOf(item.name) < 0) {
                        let newAspectsReviewed = [...aspectsReviewed];
                        newAspectsReviewed.push(item.name);
                        setAspectsReviewed(newAspectsReviewed);
                      }

                      !showResults &&
                        setAspectShown(
                          aspectShown === item.name ? null : item.name
                        );
                    }}
                  >
                    {aspectsReviewed.indexOf(item.name) < 0 ? (
                      <>
                        <i className="fa-solid fa-exclamation-circle toReview" />
                        <div className="needsReview">
                          (You haven't yet sorted candidates for this issue.)
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {showResults && (
                      <i
                        className="fa-solid fa-grip-vertical"
                        style={{
                          position: "absolute",
                          right: "0",
                          color: "gray",
                        }}
                      />
                    )}
                    <i
                      className={"fa-solid " + item.icon}
                      style={{
                        color: item.color,
                        opacity: showResults ? "" + 1 - i * 0.15 + "" : "1",
                      }}
                    />{" "}
                    <span class={aspectShown === item.name ? "" : "aspectName"}>
                      {item.name}
                    </span>
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            ) : (
              <Reorder.Group
                axis="x"
                values={items}
                onReorder={setItems}
                as={"div"}
                key="rankedListAspects"
                className="issuesList"
                style={{ paddingInlineStart: "0px" }}
              >
                {items.map((item, i) => (
                  <Reorder.Item
                    key={item.name}
                    value={item}
                    as={"div"}
                    dragListener={showResults}
                    className={
                      (aspectShown === item.name && !showResults
                        ? "aspectNameShown"
                        : "aspect") +
                      (aspectsReviewed.indexOf(item.name) < 0
                        ? " unreviewed"
                        : "")
                    }
                    style={{
                      position: "relative",
                      cursor: !showResults ? "pointer" : "grab",
                      color: showResults
                        ? "rgb(" + i * 40 + "," + i * 40 + "," + i * 40 + ")"
                        : "rgb(0,0,0)",

                      fontWeight: showResults ? "bold" : "",
                    }}
                    onClick={() => {
                      // update our list of aspects reviewed
                      if (aspectsReviewed.indexOf(item.name) < 0) {
                        let newAspectsReviewed = [...aspectsReviewed];
                        newAspectsReviewed.push(item.name);
                        setAspectsReviewed(newAspectsReviewed);
                      }

                      !showResults &&
                        setAspectShown(
                          aspectShown === item.name ? null : item.name
                        );
                    }}
                  >
                    {aspectsReviewed.indexOf(item.name) < 0 ? (
                      <>
                        <i className="fa-solid fa-exclamation-circle toReview" />
                        <div className="needsReview">
                          (You haven't yet sorted candidates for this issue.)
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {showResults && <>{i + 1}.&nbsp;</>}
                    <i
                      className={"fa-solid " + item.icon}
                      style={{
                        color: item.color,
                        opacity: showResults ? "" + 1 - i * 0.15 + "" : "1",
                      }}
                    />{" "}
                    {!showResults && (
                      <span
                        class={aspectShown === item.name ? "" : "aspectName"}
                      >
                        {item.name}
                      </span>
                    )}
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            )}
          </div>
          {!showResults && aspectShown && (
            <motion.div key={"aspectShown"} className="aspectShown">
              (Drag to sort by preference)
              {/* <i
            style={{
              fontSize: "2em",
              marginLeft: "40px",
              color: items.find((l) => l.name === aspectShown).color,
            }}
            className={
              "fa-solid " + items.find((l) => l.name === aspectShown).icon
            }
          /> */}
              <CandidatesRanked
                aspect={aspectShown}
                notes={notes}
                changeOrder={changeOrder}
                candidates={candidates}
                linkClicked={linkClicked}
                saveToMongo={saveToMongo}
                candidatesOrder={
                  items.find((l) => l.name === aspectShown).candidatesOrder
                }
              />
            </motion.div>
          )}
          {showResults && (
            <>
              <FinalResults
                finalResults={finalResults}
                candidates={candidates}
                duplicate={duplicate}
                candidateCoords={candidateCoords}
                notes={notes}
                items={items}
                setShowResults={setShowResults}
                linkClicked={linkClicked}
                saveToMongo={saveToMongo}
              />

              <Contact
                showNameField="Let us know what you think!"
                buttonText="Submit"
                buttonColor="dark"
              />
              {/* <Takeaways
                setShowResults={setShowResults}
                candidates={candidates}
                finalResults={finalResults}
                notes={notes}
                items={items}
                candidateCoords={candidateCoords}
                duplicate={duplicate}
              /> */}
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

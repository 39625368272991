import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm, Controller } from "react-hook-form";
import FormAlert from "./components/FormAlert";
import contact from "./util/contact";

function Contact(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [userRating, setUserRating] = useState("def");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: "", email: "", message: "", rating: "def" },
  });

  const onSubmit = (data) => {
    data.rating = userRating;
    // Show pending indicator
    setPending(true);

    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div style={{ marginTop: "150px", textAlign: "center" }}>
      {!showForm && (
        <>
          <h2>PollySort is a new, experimental project.</h2>
          <h3
            style={{ border: "1px solid black", cursor: "pointer" }}
            onClick={() => setShowForm(true)}
          >
            Please let us know what you think!
          </h3>
        </>
      )}
      {formAlert && showForm && (
        <>
          <FormAlert type={formAlert.type} message={formAlert.message} />
          {formAlert.type === "success" && (
            <div>
              For non-political decisions, be sure to check out{" "}
              <a href="https://www.decisionsort.com">www.decisionsort.com</a>
            </div>
          )}
        </>
      )}
      {showForm && (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group as={Col} xs={12} sm={6} controlId="formRating">
              <Controller
                name="rating"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    as="select"
                    value={userRating}
                    onChange={(e) => {
                      setUserRating(e.target.value);
                    }}
                  >
                    How much do you like PollySort?
                    <option value="def">Please select</option>
                    <option value="5">5 (love it!)</option>
                    <option value="4">4</option>
                    <option value="3">3 (meh)</option>
                    <option value="2">2</option>
                    <option value="1">1 (hate it!)</option>
                  </Form.Control>
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <Controller
                name="name"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="(Optional) Name"
                    isInvalid={errors.name}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={12} sm={6} controlId="formEmail">
              <Controller
                name="email"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="(Optional) Email"
                    isInvalid={errors.name}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={12} sm={6} controlId="formMessage">
              {/* <Form.Label>Message</Form.Label> */}
              <Controller
                name="message"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    as="textarea"
                    rows={5}
                    placeholder="(Optional) Feedback"
                    isInvalid={errors.name}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Button
            variant={props.buttonColor}
            size="lg"
            type="submit"
            disabled={pending}
          >
            <span>{props.buttonText}</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Sending...</span>
              </Spinner>
            )}
          </Button>
        </Form>
      )}
    </div>
  );
}

export default Contact;

import { useState } from "react";

export default function CountdownTimer(props) {
  // Set the date we're counting down to
  const countDownDate = props.countdownDate;
  const [countdownText, setCountdownText] = useState("Loading...");
  // Update the count down every 1 second
  const x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      setCountdownText("EXPIRED");
    }
    // Display the result in the element with id="demo"
    setCountdownText(days + " days until " + props.countdownName);
    //" + hours + "h " + minutes + "m " + seconds + "s
  }, 1000);
  return (
    <div className="countdownTimer">
      <a
        href="https://www.usatoday.com/story/news/politics/2023/11/09/when-is-fourth-republican-debate/71517488007/"
        target="_blank"
      >
        {countdownText}
      </a>
    </div>
  );
}


// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
    return fetch(url, {
      method: method,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data ? JSON.stringify(data) : undefined,
    }).then((response) => response.json());
  }
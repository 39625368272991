import { apiRequestExternal } from "./util.js";
const {FORMSPREE_CONTACT_ID} =process.env;
const endpoint = 'https://formspree.io/f/xdorvdzb';

function submit(data) {
  return apiRequestExternal(endpoint, "POST", data);
}

const contact = { submit };

export default contact;

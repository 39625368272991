// import { asaNotes } from "./notes/asaNotes";
import { chrisNotes } from "./notes/chrisNotes";
import { donNotes } from "./notes/donNotes";
import { dougNotes } from "./notes/dougNotes";
// import { mikeNotes } from "./notes/mikeNotes";
import { nikkiNotes } from "./notes/nikkiNotes";
import { ronNotes } from "./notes/ronNotes";
// import {timNotes} from "./notes/timNotes";
import { vivekNotes } from "./notes/vivekNotes";

export const candidates = [
  // { name: "Asa Hutchinson", img: "/images/Asa_Hutchinson.jpg" },
  { name: "Chris Christie", img: "/images/christie.jpg" },
  { name: "Donald Trump", img: "/images/trump.jpg" },
  { name: "Doug Burgum", img: "/images/burgum.jpg" },
  // { name: "Francis Suarez", img: "/images/FrancisSuarez.jpg" },
  // { name: "Mike Pence", img: "/images/Mike_Pence.jpg" },
  { name: "Nikki Haley", img: "/images/haley.jpg" },
  { name: "Ron DeSantis", img: "/images/desantis.jpg" },
  // { name: "Tim Scott", img: "/images/scott.jpg" },
  { name: "Vivek Ramaswamy", img: "/images/ramaswamy.jpg" },
  // { name: "Will Hurd", img: "/images/Will_Hurd.jpg" },
];
export const notes = {
  // "Asa Hutchinson": asaNotes,
  "Chris Christie": chrisNotes,
  "Donald Trump": donNotes,
  "Doug Burgum": dougNotes,
  // "Francis Suarez": [{
  //   title: "Twitter",
  //   clips: [
  //     {
  //       content: [""],
  //       sourceName: "Twitter",
  //       link: "https://twitter.com/FrancisSuarez",
  //     },
  //   ],
  // },
  // {
  //   title: "Website",
  //   clips: [
  //     {
  //       content: [""],
  //       sourceName: "Website",
  //       link: "https://sosamericapac.org/",
  //     },
  //   ],
  // },
  // ],
  // "Mike Pence": mikeNotes,
  "Nikki Haley": nikkiNotes,
  "Ron DeSantis": ronNotes,
  // "Tim Scott": timNotes,
  "Vivek Ramaswamy": vivekNotes,
  // "Will Hurd": [    {
  //   title: "Twitter",
  //   clips: [
  //     {
  //       content: [""],
  //       sourceName: "Twitter",
  //       link: "https://twitter.com/WillHurd",
  //     },
  //   ],
  // },
  // {
  //   title: "Website",
  //   clips: [
  //     {
  //       content: [""],
  //       sourceName: "Website",
  //       link: "https://www.willbhurd.com/",
  //     },
  //   ],
  // },
  // ],
};
export const List = [
  {
    name: "Domestic Policy",
    icon: "fa-landmark-dome",
    color: "gray",
    candidatesOrder: ["a", 0, 1, 2, 3, 4, 5, 6, "d"],
  },
  {
    name: "Economic Policy",
    icon: "fa-dollar",
    color: "green",
    candidatesOrder: ["a", 0, 1, 2, 3, 4, 5, 6, "d"],
  },
  {
    name: "Foreign Policy",
    icon: "fa-globe",
    color: "blue",
    candidatesOrder: ["a", 0, 1, 2, 3, 4, 5, 6, "d"],
  },
  {
    name: "Person / Character",
    icon: "fa-user",
    color: "black",
    candidatesOrder: ["a", 0, 1, 2, 3, 4, 5, 6, "d"],
  },
  {
    name: "Experience",
    icon: "fa-clock",
    color: "#88400d",
    candidatesOrder: ["a", 0, 1, 2, 3, 4, 5, 6, "d"],
  },
];

export const dougNotes = [
    {
      title: "Twitter",
      clips: [
        {
          content: [""],
          sourceName: "Twitter",
          link: "https://twitter.com/GovDougBurgum",
        },
      ],
    },
    {
      title: "Website",
      clips: [
        {
          content: [""],
          sourceName: "Website",
          link: "https://www.dougburgum.com/",
        },
      ],
    },
    {
      title: "Domestic Policy",
      clips: [
        {
          content: [
            'Gov. Burgum\'s top priorities are the economy, energy, and national security. "He knows if we get [those three] right, we will unlock the best of America and improve every American life."',
            "On national security: Doug believes we need to recognize the real threats to America and strengthen national security. Our enemies aren’t our neighbors down the street. Our enemies are countries that want to see our way of life destroyed.",
          ],
          sourceName: "Campaign Website",
          source: "https://www.dougburgum.com/",
        },
        {
          content: [
            "Gov. Burgum signed one of the strictest anti-abortion laws in the country.",
          ],
          sourceName: "USA Today",
          source:
            "https://www.usatoday.com/story/news/nation/2023/04/24/north-dakota-governor-doug-burgum-abortion-law/11733233002/",
        },
        {
          content: [
            'He is not in favor of a national abortion ban, but rather believes it should be decided at the state level -- "What would fly in New York would never fly in North Dakota. No way."',
          ],
          sourceName: "USA Today",
          source:
            "https://www.usatoday.com/story/news/politics/2023/07/26/who-is-doug-burgum-2024/70456203007/",
        },
        {
          content: [
            "Burgum believes human activity has caused climate change, and as governor he made it a goal to get the Roughrider State carbon-neutral by 2030. But he rejects the Democratic worldview of using regulation to curtail fossil fuel use and instead emphasizes innovative technology to capture carbon emissions.",
          ],
          sourceName: "USA Today",
          source:
            "https://www.usatoday.com/story/news/politics/2023/07/26/who-is-doug-burgum-2024/70456203007/",
        },
      ],
    },
    {
      title: "Economy",
      clips: [
        {
          content: [
            'He says, "We need to stop buying energy from our enemies and start selling energy to our friends and allies. America produces energy cleaner and safer than anywhere else in the world. When America becomes truly energy independent and supports our allies we prevent wars like Putin started in Ukraine. We stabilize the globe and restore America as the leader of the free world."',
            'He intends to "get inflation under control, cut taxes, lower gas prices, reduce the cost of living and help people realize their fullest potential."',
          ],
          sourceName: "Campaign Website",
          source: "https://www.dougburgum.com/",
        },
      ],
    },
    {
      title: "Experience",
      clips: [
        {
          content: [
            "Gov Burgum is governor of one of the largest energy producing states in America.",
            "He is formerly a business leader who built a software company that served 140,000 different businesses in 132 countries and was eventually acquired by Microsoft.",
          ],
          sourceName: "Campaign Website",
          source: "https://www.dougburgum.com/",
        },
      ],
    },
  ]
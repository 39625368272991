import "./SimpleMode.css";
import { AnimatePresence, Reorder, motion } from "framer-motion";
import { useEffect, useState } from "react";

export default function CandidatesRanked(props) {
  const [items, setItems] = useState(props.candidatesOrder || []);
  const [showNotes, setShowNotes] = useState(null);
  useEffect(() => {
    setItems(props.candidatesOrder);
  }, [props.candidatesOrder]);
  const notesClicked = (name) => {
    let currentNoteClicks = localStorage.getItem("notesClicked");
    if (!currentNoteClicks)
      localStorage.setItem(
        "notesClicked",
        JSON.stringify([
          {
            note: name,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    else
      localStorage.setItem(
        "notesClicked",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("notesClicked")),
          {
            note: name,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    props.saveToMongo();
    setShowNotes(showNotes === name ? null : name);
  };
  return (
    <>
      {items.indexOf("a") === 0 && <div>(Drag favorable candidates here)</div>}
      {items.indexOf("a") > 1 && (
        <>
          {/* <div>(Drag to sort)</div> */}
          {/* <div
            style={{
              position: "absolute",
              left: "-30px",
              top: "140px",
              transform: "rotate(270deg)",
              color: "gray",
            }}
          >
            Favorability&nbsp;&nbsp;
            <i className="fa-solid fa-arrow-right" />
          </div> */}
        </>
      )}
      <Reorder.Group
        key={"rankedListOfCandidates"}
        axis="y"
        values={items}
        className={"candidateList"}
        onReorder={(e) => {
          props.changeOrder(e);
        }}
        initial={{
          height: 0,
          opacity: 0,
        }}
        animate={{
          height: "auto",
          opacity: 1,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
              delay: 0.15,
            },
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
            },
          },
        }}
      >
        {items.map((item, i) =>
          item === "d" ? (
            <Reorder.Item key={item} value={item} className="divider">
              <div style={{ borderBottom: "1px solid gray" }}>Neutral </div>
              <div className="unfavorable">
                <i className="fa-solid fa-arrow-down" /> Unfavorable{" "}
                <i className="fa-solid fa-arrow-down" />
              </div>
            </Reorder.Item>
          ) : item === "a" ? (
            <Reorder.Item key={item} value={item} className="divider">
              <div
                style={{ borderBottom: "1px solid gray" }}
                className="favorable"
              >
                <i className="fa-solid fa-arrow-up" /> Favorable{" "}
                <i className="fa-solid fa-arrow-up" />
              </div>
              <div>Neutral</div>
            </Reorder.Item>
          ) : (
            <Reorder.Item
              key={item}
              value={item}
              className={"candidate "}
              style={{
                position: "relative",
                fontWeight:
                  showNotes === props.candidates[item].name ? "bold" : "",
                background:
                  "white " +
                  (i < items.indexOf("a")
                    ? "linear-gradient(rgba(224, 252, 224," +
                      (1 - i / items.indexOf("a")) +
                      ") 0%, #fff 100%)"
                    : i < items.indexOf("d")
                    ? ""
                    : "linear-gradient(#fff 0%, rgba(252,224,228," +
                      (i - items.indexOf("d")) /
                        (props.candidates.length + 1 - items.indexOf("d")) +
                      ") 100%)"),
              }}
              onClick={() =>
                showNotes === props.candidates[item].name
                  ? setShowNotes(null)
                  : ""
              }
            >
              <i
                className="fa-solid fa-grip-vertical"
                style={{ color: "gray", position: "relative", left: "-5px" }}
              />
              <div
                className="infoBar"
                style={
                  showNotes === props.candidates[item].name
                    ? { opacity: "1", fontSize: "1.2em" }
                    : {}
                }
                onClick={() => notesClicked(props.candidates[item].name)}
              >
                <i className="fa-solid fa-info" />
              </div>
              <img
                draggable={false}
                src={props.candidates[item].img}
                style={{
                  height: "70px",
                  aspectRatio: "initial",
                  marginRight: "10px",
                  borderRadius: "5px",
                }}
              />{" "}
              {props.candidates[item].name}
              <AnimatePresence>
                {showNotes === props.candidates[item].name &&
                  props.notes[showNotes] && (
                    <motion.div
                      initial={{
                        height: 60,
                        opacity: 0,
                        right: 0,
                        width: 0,
                      }}
                      animate={{
                        height: "auto",
                        opacity: 1,
                        right: -385,
                        width: 360,
                        transition: {
                          height: {
                            duration: 0.4,
                            delay: 0.3,
                          },
                          opacity: {
                            duration: 0.4,
                          },
                        },
                      }}
                      exit={{
                        height: 60,
                        opacity: 0,
                        width: 0,
                        right: 0,
                        transition: {
                          height: {
                            duration: 0.4,
                          },
                          width: {
                            delay: 0.4,
                          },
                          right: {
                            delay: 0.4,
                          },
                          opacity: {
                            delay: 0.4,
                          },
                        },
                      }}
                      className="notes"
                      style={{
                        position: "absolute",
                        right: "-390px",
                        top: "10px",
                        cursor: "default",
                        overflow: "hidden",
                      }}
                    >
                      {props.notes[showNotes]
                        .filter(
                          (n) =>
                            n.title === "Twitter" ||
                            n.title === "Truth Social" ||
                            n.title === "Website" ||
                            n.title === props.aspect
                        )
                        .map((n) => (
                          <div onClick={(e) => e.stopPropagation()}>
                            {n.title === props.aspect && <h4>On {n.title}:</h4>}
                            {n.clips &&
                              n.clips.map((clip) => (
                                <div style={{ textAlign: "left" }}>
                                  {clip.sourceName && (
                                    <ul
                                      style={{
                                        clear: "both",
                                        fontWeight: "normal",
                                        textAlign: "left",
                                        width: "300px",
                                      }}
                                    >
                                      {clip.content.map((cnt) => (
                                        <li style={{ margin: "5px" }}>
                                          {cnt} (
                                          <a onClick={() => props.linkClicked(clip.link || clip.source)}
                                            target={"_blank"}
                                            href={clip.link || clip.source}
                                          >
                                            {clip.link || clip.sourceName}
                                          </a>
                                          )
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}
                    </motion.div>
                  )}
              </AnimatePresence>
            </Reorder.Item>
          )
        )}
      </Reorder.Group>
      {items.indexOf("d") === items.length - 1 && (
        <div>(Drag unfavorable candidates here)</div>
      )}
    </>
  );
}

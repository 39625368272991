import { useCallback, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
//import FinalRankedList from "./FinalRankedList";
import "./final.css";

export default function FinalResults({ candidateCoords, duplicate, ...props }) {
  const [showName, setShowName] = useState(null);
  const [showNotes, setShowNotes] = useState(null);


  const paretoCandidates = useMemo(() => {
    let sortedByBen = [...props.candidates].sort(
      (a, b) =>
        candidateCoords[1][b.name] - candidateCoords[1][a.name] ||
        candidateCoords[0][a.name] - candidateCoords[0][b.name]
    );
    let paretoSet = [sortedByBen.shift()];
    let remainingSet = sortedByBen.filter(
      (candidate) =>
        candidateCoords[0][candidate.name] <=
        candidateCoords[0][paretoSet[paretoSet.length - 1].name]
    );
    while (remainingSet.length > 0) {
      if (
        (candidateCoords[0][remainingSet[0].name] ===
          candidateCoords[0][paretoSet[paretoSet.length - 1].name] &&
          candidateCoords[1][remainingSet[0].name] ===
            candidateCoords[1][paretoSet[paretoSet.length - 1].name]) ||
        candidateCoords[0][remainingSet[0].name] <
          candidateCoords[0][paretoSet[paretoSet.length - 1].name]
      ) {
        paretoSet.push(remainingSet.shift());
      } else remainingSet.shift();
      remainingSet = remainingSet.filter(
        (candidate) =>
          candidateCoords[0][candidate.name] <=
          candidateCoords[0][paretoSet[paretoSet.length - 1].name]
      );
    }
    return paretoSet;
  }, [candidateCoords, props.candidates]);

  const neutrals = useMemo(() => {
    let neuts = {};
    props.items.forEach((item) => {
      item.candidatesOrder
        .filter(
          (co, i) =>
            i > item.candidatesOrder.findIndex((ico) => ico === "a") &&
            i < item.candidatesOrder.findIndex((ico) => ico === "d")
        )
        .forEach((candidateIndex) => {
          if (!neuts[props.candidates[parseInt(candidateIndex)].name])
            neuts[props.candidates[parseInt(candidateIndex)].name] = [];
          neuts[props.candidates[parseInt(candidateIndex)].name].push(
            item.name
          );
        });
    });
    return neuts;
  }, [props.candidates, props.items]);

  const oneDimensionals = useMemo(() => {
    let og = [];
    let ob = [];
    let on = [];
    props.candidates.forEach((candidate, i) => {
      if (!neutrals[candidate.name]) {
        if (
          props.finalResults["favorable"][i] === 0 &&
          props.finalResults["unfavorable"][i] === 0
        )
          on.push(candidate.name);
        else if (
          props.finalResults["unfavorable"][i] > 0 &&
          props.finalResults["favorable"][i] === 0
        )
          ob.push(candidate.name);
        else if (
          props.finalResults["favorable"][i] > 0 &&
          props.finalResults["unfavorable"][i] === 0
        )
          og.push(candidate.name);
      }
    });
    return [og, ob, on];
  }, [props.candidates, props.finalResults, neutrals]);

  const learnMore = useMemo(() => {
    let lm = [];
    props.candidates.forEach((candidate, i) => {
      if (
        neutrals[candidate.name] &&
        (neutrals[candidate.name].length > 2 ||
          (neutrals[candidate.name].length > 0 &&
            neutrals[candidate.name].indexOf(props.items[0].name) >= 0))
      )
        lm.push(candidate.name);
    });
    console.log(lm);
    return lm;
  }, [neutrals, props.candidates, props.items]);

  const learnMoreText = useCallback(
    (lm) => {
      return (
        <>
          {!paretoCandidates.find((pc) => pc.name === lm) && (
            <img
              src={props.candidates.find((c) => c.name === lm).img}
              style={{
                height: "70px",
                aspectRatio: "initial",
                marginRight: "10px",
                marginTop: "20px",
                borderRadius: "5px",
                float: "left",
              }}
            />
          )}
          <div style={{ color: "darkgoldenrod", marginTop: "15px" }}>
            <i
              className="fa-solid fa-info"
              style={{
                color: "black",
                backgroundColor: "white",
                border: "2px solid black",
                padding: "5px",
                fontSize: "0.7em",
              }}
            />
            Consider learning more
          </div>{" "}
          about {lm}
          {neutrals[lm].length < 4 ? (
            <>
              's{" "}
              <i>
                {neutrals[lm]
                  .sort((a, b) => b - a)
                  .map((nl, i) => {
                    return nl + (i === neutrals[lm].length - 1 ? "" : " and ");
                  })}
              </i>
            </>
          ) : (
            <> </>
          )}
          to be less neutral:
          <br />
          <a onClick={() => props.linkClicked(props.notes[lm].find((n) => n.title === "Website")
                    .clips[0].link)}
            href={
              props.notes[lm].find((n) => n.title === "Website").clips[0].link
            }
            target="_blank"
          >
            {props.notes[lm].find((n) => n.title === "Website").clips[0].link}
          </a>
        </>
      );
    },
    [neutrals, props.notes, paretoCandidates, props.candidates]
  );

  const paretoText = useCallback(
    (name) => {
      return paretoCandidates.length > 1 && !name ? (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {paretoCandidates.map((ogc, i) => (
              <>
                <div>
                  <img
                    src={ogc.img}
                    style={{
                      height: "70px",
                      aspectRatio: "initial",
                      marginRight: "10px",
                      borderRadius: "5px",
                      verticalAlign: "middle",
                    }}
                  />
                  <br />
                  {ogc.name}
                </div>
              </>
            ))}
          </div>
          <div>
            are{" "}
            <span style={{ color: "darkgreen", fontWeight: "bold" }}>
              most favorable
            </span>
            . <br />
            (Does re-sorting Issues change anything?)
          </div>
        </>
      ) : (
        (
          (name && paretoCandidates.filter((pc) => pc.name === name)) ||
          paretoCandidates
        ).map((ogc, i) => (
          <>
            <img
              src={ogc.img}
              style={{
                height: "70px",
                aspectRatio: "initial",
                marginRight: "10px",
                marginTop: "25px",
                borderRadius: "5px",
                float: "left",
              }}
            />
            <div
              style={{
                height: "70px",
              }}
            >
              <i
                className="fa-solid fa-star"
                style={{
                  color: "green",
                  backgroundColor: "white",
                  border: "2px solid green",
                  fontSize: "0.7em",
                }}
              />
              <span style={{ fontWeight: "bold", color: "darkgreen" }}>
                {" " + ogc.name}
              </span>{" "}
              is {paretoCandidates.length > 1 ? "one of" : ""} your most{" "}
              <span style={{ fontWeight: "bold", color: "darkgreen" }}>
                clearly favored
              </span>{" "}
              candidate{paretoCandidates.length > 1 ? "s" : ""}.
            </div>
            <div>
              You could{" "}
              <a onClick={() => props.linkClicked(props.notes[ogc.name].find((n) => n.title === "Website")
                    .clips[0].link)}
                href={
                  props.notes[ogc.name].find((n) => n.title === "Website")
                    .clips[0].link
                }
                target="_blank"
              >
                donate or volunteer
              </a>{" "}
              to help.
            </div>
          </>
        ))
      );
    },
    [paretoCandidates, props.notes,props.linkClicked]
  );

  const areYouSureText = useCallback((candidate, onlyGood) => {
    return (
      <>
        {" "}
        <div style={{ margin: "10px auto 0px auto" }}>
          {" "}
          <img
            src={candidate.img}
            style={{
              height: "70px",
              aspectRatio: "initial",
              borderRadius: "5px",
              verticalAlign: "middle",
              marginTop: "-20px",
            }}
          />
          <i
            className="fa-solid fa-question"
            style={{
              margin: "0px 0px 0px 15px",
              fontSize: "3em",
              color: "darkred",
              backgroundColor: "none",
            }}
          />
        </div>
        <div style={{ height: "70px", marginTop: "25px",marginBottom:"10px" }}>
          Are you sure you view {candidate.name}{" "}
          {onlyGood ? (
            <span style={{ color: "green", fontWeight: "bold" }}>
              favorably
            </span>
          ) : (
            <span style={{ color: "darkred", fontWeight: "bold" }}>
              unfavorably
            </span>
          )}{" "}
          for <em>every</em> issue?
          <br />
          (Try to consider each issue independently back in candidate{" "}
          <span
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => props.setShowResults(false)}
          >
          Rankings</span>.)
        </div>
      </>
    );
  },[props]);

  const determineNotes = useCallback(
    (candidate) => {
      return (
        <>
          {paretoCandidates.find((pc) => pc.name === candidate.name) ? (
            paretoText(candidate.name)
          ) : (
            <></>
          )}
          {learnMore.indexOf(candidate.name) >= 0 ? (
            learnMoreText(candidate.name)
          ) : (
            <></>
          )}
          {oneDimensionals[1].indexOf(candidate.name) >= 0 ? (
            areYouSureText(candidate, false)
          ) : (
            <></>
          )}
          {!paretoCandidates.find((pc) => pc.name === candidate.name) &&
          learnMore.indexOf(candidate.name) < 0 &&
          oneDimensionals[1].indexOf(candidate.name) < 0 ? (
            <>
              There seems to be at least one better candidate for you than{" "}
              {candidate.name}.<br />
              (You could try re-sorting the Issues to see if that changes.)
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    [learnMore, learnMoreText, oneDimensionals, paretoCandidates, paretoText,areYouSureText]
  );

  const candidateClicked = (candidate) => {
    let currentCandidateClicks = localStorage.getItem("candidatesClicked");
    if (!currentCandidateClicks)
      localStorage.setItem(
        "candidatesClicked",
        JSON.stringify([
          {
            candidateName: candidate.name,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    else
      localStorage.setItem(
        "candidatesClicked",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("candidatesClicked")),
          {
            candidateName: candidate.name,
            clickedOn: new Date(Date.now()).toUTCString(),
          },
        ])
      );
    props.saveToMongo();
    setShowNotes(determineNotes(candidate));
  }

  useEffect(() => {
    setShowNotes(paretoText());
  }, [paretoCandidates, paretoText, setShowNotes]);

  return (
    candidateCoords.length > 0 && (
      <div className="finalWrapper">
        {showNotes && <div className="finalShownNote">{showNotes}</div>}
        <div className="favorableFinal">Favorable&nbsp;</div>
        <div className="unfavorableFinal">Unfavorable&nbsp;</div>
        <div className="neutralFinal">Neutral&nbsp;</div>
        <div className="finalResults">
          <div className="whiteGradient">&nbsp;</div>
          &nbsp;
        </div>

        {props.candidates.map((candidate, i) => {
          const dup = duplicate(candidate.name);
          return (
            <motion.div
              key={candidate.name}
              style={{
                transition: "all 0.5s ease",
                position: "absolute",
                top:
                  480 - 48 * candidateCoords[1][candidate.name] + (dup ? i : 0),
                left:
                  48 * candidateCoords[0][candidate.name] + (dup ? i * 4 : 0),
                scale: dup ? "0.7" : "1",
                border: dup ? "4px solid goldenrod" : "none",
                borderRadius: "35px",
              }}
              className="finalResultsCandidateIcon"
              onMouseOver={(e) => setShowName(candidate.name)}
              onMouseOut={() => setShowName(null)}
            >
              <div
                style={{
                  display:
                    paretoCandidates
                      .map((p) => p.name)
                      .indexOf(candidate.name) >= 0
                      ? "block"
                      : "none",
                  border: "2px 2px green",
                  position: "absolute",
                  fontSize: "8px",
                  zIndex: "1",
                }}
                onClick={() => candidateClicked(candidate)}
              >
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: "green",
                    backgroundColor: "white",
                    border: "2px solid green",
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    paretoCandidates
                      .map((p) => p.name)
                      .indexOf(candidate.name) >= 0
                      ? "block"
                      : "none",
                  border: "2px 2px green",
                  position: "absolute",
                  fontSize: "8px",
                  zIndex: "1",
                }}
                onClick={() => candidateClicked(candidate)}
              >
                <i
                  className="fa-solid fa-star"
                  style={{
                    color: "green",
                    backgroundColor: "white",
                    border: "2px solid green",
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    learnMore.indexOf(candidate.name) >= 0 ? "block" : "none",
                  border: "2px 2px black",
                  position: "absolute",
                  right: "-10px",
                  fontSize: "10px",
                  zIndex: "1",
                }}
                onClick={() => candidateClicked(candidate)}
              >
                <i
                  className="fa-solid fa-info"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    border: "2px solid black",
                    padding: "5px",
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    oneDimensionals[1].indexOf(candidate.name) >= 0
                      ? "block"
                      : "none",
                  border: "2px 2px darkred",
                  position: "absolute",
                  right: "-10px",
                  fontSize: "10px",
                  zIndex: "1",
                }}
                onClick={() => candidateClicked(candidate)}
              >
                <i
                  className="fa-solid fa-question"
                  style={{
                    color: "darkred",
                    backgroundColor: "white",
                    border: "2px solid darkred",
                    padding: "5px",
                  }}
                />
              </div>
              <img
                draggable={false}
                alt={"candidate"}
                src={candidate.img}
                style={{
                  width: "50px",
                  verticalAlign: "middle",
                  clipPath: "circle(50%)",
                }}
                onClick={() => candidateClicked(candidate)}
              />
              {showName === candidate.name && (
                <div
                  className="finalResultsCandidateName"
                  onClick={() => candidateClicked(candidate)}
                >
                  {candidate.name + (dup ? " (tie)" : "")}
                </div>
              )}
            </motion.div>
          );
        })}
      </div>
    )
  );
}

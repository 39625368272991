import { AnimatePresence, Reorder, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export default function InitialQuestions(props) {
  const candidatesFavor = useRef([]);
  const [currentCandidate, setCurrentCandidate] = useState(0);
  const setCandidatesFavor = (candidatename, favor) => {
    if (!candidatesFavor.current.find((cf) => cf.id === currentCandidate)) {
      candidatesFavor.current.push({ id: currentCandidate, favored: favor });
      if (currentCandidate === props.candidates.length - 1)
        props.endInitQuestions(candidatesFavor.current);
      else setCurrentCandidate(currentCandidate + 1);
    }
  };
  const candidateRef = useRef(null);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const startingPos = useRef([50, 200]);
  useEffect(() => {
    setShowGreenCheck(true);
    setTimeout(() => setShowGreenCheck(false), 500);
  }, [currentCandidate]);
  return (
    <motion.div
      style={{ textAlign: "center",position:"relative" }}
      key={"initQuestions"}
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: "auto",
        opacity: 1,
        transition: {
          height: {
            duration: 1,
            delay: 1,
          },
          opacity: {
            duration: 1,
            delay: 1,
          },
        },
      }}
      exit={{
        height: 0,
        opacity: 0,
        transition: {
          height: {
            duration: 0.2,
          },
          opacity: {
            duration: 1,
          },
        },
      }}
    >
      <AnimatePresence>
        {showGreenCheck && (
          <motion.div
            key="greenCheck"
            className="bigGreenCheck"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                opacity: {
                  duration: 0.2,
                },
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                opacity: {
                  duration: 1,
                },
              },
            }}
          >
            <i className="fa-solid fa-check" />
          </motion.div>
        )}
      </AnimatePresence>
      <h3>
        <>
          <i className="fa-solid fa-star" style={{ float: "left" }} />
          <i className="fa-solid fa-star" style={{ float: "right" }} />
          1. Quick Sort Each Candidate:
          <div style={{ fontSize: "0.8em", fontWeight: "normal" }}>
            (Just react! Don't overthink it.)
          </div>
        </>
      </h3>
      <AnimatePresence>
        <motion.div
          className="initialQuestionsHolder"
          key={props.candidates[currentCandidate].name}
          initial={{
            height: 0,
            opacity: 0,
          }}
          animate={{
            height: "auto",
            opacity: 1,
            transition: {
              height: {
                duration: 1,
                delay: 1,
              },
              opacity: {
                duration: 1,
                delay: 1,
              },
            },
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.2,
              },
              opacity: {
                duration: 1,
              },
            },
          }}
        >
          <motion.div
            key={props.candidates[currentCandidate].name + "favored"}
            className="dragPlaceholder"
          >
            Drag here if favorable
          </motion.div>
          <motion.div
            key={props.candidates[currentCandidate] + "neutral"}
            className="dragPlaceholder neutralInit"
          >
            (Neutral)
          </motion.div>
          <motion.div
            key={props.candidates[currentCandidate] + "name"}
            drag
            dragConstraints={{ left: 0, top: -80, bottom: 80, right: 250 }}
            dragElastic={0.2}
            className="candidate initialCandidate"
            ref={candidateRef}
            onDragStart={(e) => (startingPos.current = [e.x, e.y])}
            onUpdate={(e) => {
              return e.y < -50 && e.x < 100
                ? setCandidatesFavor(props.candidates[currentCandidate].name, 1)
                : e.y > 80 && e.x < 100
                ? setCandidatesFavor(
                    props.candidates[currentCandidate].name,
                    -1
                  )
                : e.x > 200 && e.y > -50 && e.y < 50
                ? setCandidatesFavor(props.candidates[currentCandidate].name, 0)
                : "";
            }}
          >
            <i
              className="fa-solid fa-grip-vertical"
              style={{ position: "relative", left: "-5px", color: "gray" }}
            />
            <img
              draggable={false}
              src={props.candidates[currentCandidate].img}
              style={{
                width: "50px",
                marginRight: "50px",
                borderRadius: "5px",
              }}
            />{" "}
            {props.candidates[currentCandidate].name}
          </motion.div>

          <motion.div
            key={props.candidates[currentCandidate] + "unfavored"}
            className="dragPlaceholder"
          >
            Drag here if unfavorable
          </motion.div>
          {/* 
        <button onClick={() => setCurrentCandidate(currentCandidate + 1)}>
          {">"}
        </button> */}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}

export const donNotes = [{
    title: "Truth Social",
    clips: [
      {
        content: [""],
        sourceName: "Truth Social",
        link: "https://www.truthsocial.com/",
      },
    ],
  },
  {
    title: "Website",
    clips: [
      {
        content: [""],
        sourceName: "Website",
        link: "https://www.donaldjtrump.com/mission",
      },
    ],
  },
  ]
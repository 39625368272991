export const ronNotes = [{
    title: "Twitter",
    clips: [
      {
        content: [""],
        sourceName: "Twitter",
        link: "https://twitter.com/RonDeSantis",
      },
    ],
  },
  {
    title: "Website",
    clips: [
      {
        content: [""],
        sourceName: "Website",
        link: "https://rondesantis.com/",
      },
    ],
  }
  ]